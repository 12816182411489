import React, { useEffect, useMemo, useState } from 'react'
import { Button, Form, InputGroup, Table } from 'react-bootstrap'
import { Calendar } from '../../shared/components/Icons'
import useFetch from '../../shared/hooks/useFetch'
import { Link } from 'react-router-dom'
import usePowerOffs from '../../shared/hooks/usePowerOff'
import moment from 'moment'
import LimitationDate from './LimitationDate'
import { useIntl } from 'react-intl'
import setLimitation from '../../shared/lib/setLimitation'
import useLimitations from '../../shared/hooks/useLimitations'
import createMomentObject from '../../shared/lib/createMomentObject'

export default function LimitationsTable({ preselectForecasts }) {
    const { data: systems } = useFetch({ url: '/api/v1/systems' })
    const { powerOffs, fetchPowerOffs, deletePowerOff, powerOnBySystemId } = usePowerOffs()

    const { limitations, fetchLimitations } = useLimitations()

    const latestLimitations = useMemo(() => {
        return limitations
            ?.filter((limitation) => moment(limitation.date_to).unix() > moment().unix())
            .sort((a, b) => moment(b.createdate).unix() - moment(a.createdate).unix())
    }, [limitations])

    const intl = useIntl()
    const currentTimestamp = moment().unix()
    const isActive = (dateTo) => moment(dateTo).unix() >= currentTimestamp

    const latestPowerOffs = useMemo(() => {
        return powerOffs
            ?.filter((powerOff) => moment(powerOff.date_from).unix() > moment().subtract(7, 'days').unix())
            .sort((a, b) => moment(b.date_from).unix() - moment(a.date_from).unix())
    }, [powerOffs])

    const [mainSettings, setMainSettings] = useState({
        percent: null,
        powerOff: false,
        allSystemsSelect: false,
        limitation_date_from: null,
        limitation_date_to: null,
        powerOff_date_from: null,
        powerOff_date_to: null,
        limitationForecast: false,
        powerOffForecast: false,
    })

    const [allSystemsSelect, setAllSystemsSelect] = useState(false)

    const [newSystems, setNewSystems] = useState([])

    useEffect(() => {
        setMainSettings((prev) => ({
            ...prev,
            limitation_date_from: moment().add(1, 'minute'),
            powerOff_date_from: moment().add(1, 'minute'),
        }))
    }, [])

    useEffect(() => {
        setMainSettings((prev) => ({
            ...prev,
            limitation_date_to: moment().add(1, 'hours').add(1, 'minute'),
            powerOff_date_to: moment().add(1, 'hours').add(1, 'minute'),
        }))
    }, [])
    useEffect(() => {
        systems && setNewSystems([...systems])
    }, [systems])

    useEffect(() => {
        if (latestPowerOffs && newSystems) {
            setNewSystems((prev) =>
                prev.map((prevSystem) => {
                    const powerOff = latestPowerOffs.find((powerOff) => powerOff.system === prevSystem.system)
                    if (powerOff) {
                        return {
                            ...prevSystem,
                            powerOff: true,
                            powerOff_date_from: createMomentObject(powerOff.date_from),
                            powerOff_date_to: createMomentObject(powerOff.date_to),
                        }
                    } else {
                        return {
                            ...prevSystem,
                        }
                    }
                })
            )
        }
    }, [latestPowerOffs, systems])

    useEffect(() => {
        if (latestLimitations && latestPowerOffs && newSystems) {
            setNewSystems((prev) =>
                prev.map((prevSystem) => {
                    const limitation = latestLimitations.find((limitation) => limitation.system === prevSystem.system)

                    const powerOff = latestPowerOffs.find((powerOff) => powerOff.system === prevSystem.system)

                    if (limitation || powerOff) {
                        return {
                            ...prevSystem,
                            limitation_date_from: createMomentObject(limitation.date_from),
                            limitation_date_to: createMomentObject(limitation.date_to),
                            percent: limitation.percent,
                            ...(limitation && {
                                activeLimitation:
                                    moment(limitation?.date_from).unix() < moment().unix()
                                        ? limitation.limitation
                                        : null,
                            }),
                            ...(powerOff && {
                                activePowerOff:
                                    moment(powerOff?.date_from).unix() < moment().unix() ? powerOff.poweroff : null,
                            }),
                        }
                    } else {
                        return {
                            ...prevSystem,
                        }
                    }
                })
            )
        }
    }, [latestLimitations, systems, latestPowerOffs])

    useEffect(() => {
        setNewSystems((prev) =>
            prev.map((prevSystem) =>
                prevSystem.isSelected
                    ? {
                          ...prevSystem,
                          ...mainSettings,
                      }
                    : { ...prevSystem }
            )
        )
    }, [mainSettings])

    useEffect(() => {
        setNewSystems((prev) =>
            prev.map((prevSystem) =>
                allSystemsSelect
                    ? {
                          ...prevSystem,
                          ...mainSettings,
                          isSelected: allSystemsSelect,
                      }
                    : { ...prevSystem, isSelected: allSystemsSelect }
            )
        )
    }, [allSystemsSelect])

    const forecastCorrection = false

    const saveSingleLimitation = async (system) => {
        setNewSystems((prev) =>
            prev.map((prevSystem) =>
                prevSystem.system === system.system ? { ...prevSystem, isSaving: true } : { ...prevSystem }
            )
        )

        await setLimitation({
            intl,
            selectedSystem: system.system,
            forecastCorrection,
            editedLimitation: { limitation: system.activeLimitation },
            // powerOff: system.powerOff,
            limitation: {
                system: system.system,
                percent: system.percent,
                // powerOff: system.powerOff,
                date_from: moment(system.limitation_date_from, 'D.M.YYYY HH:mm').format(),
                date_to: moment(system.limitation_date_to, 'D.M.YYYY HH:mm').format(),
                limitation_affects_forecast_corrections: system.limitationForecast,
                poweroff_affects_forecast_corrections: system.powerOffForecast,
            },
        })

        system.activePowerOff
            ? await powerOnBySystemId(system.system)
            : system.powerOff &&
              (await setLimitation({
                  intl,
                  selectedSystem: system.system,
                  forecastCorrection,
                  powerOff: true,
                  editedLimitation: { limitation: system.activePowerOff },
                  // powerOff: system.powerOff,
                  limitation: {
                      system: system.system,
                      poweroff: system.powerOff,
                      date_from: moment(system.powerOff_date_from, 'D.M.YYYY HH:mm').format(),
                      date_to: moment(system.powerOff_date_to, 'D.M.YYYY HH:mm').format(),
                      limitation_affects_forecast_corrections: system.limitationForecast,
                      poweroff_affects_forecast_corrections: system.powerOffForecast,
                  },
              }))
        setNewSystems((prev) =>
            prev.map((prevSystem) =>
                prevSystem.system === system.system ? { ...prevSystem, isSaving: false } : { ...prevSystem }
            )
        )
    }

    const saveAllLimitations = async () => {
        newSystems.forEach(async (system) => system.isSelected && (await saveSingleLimitation(system)))

        fetchLimitations()
        fetchPowerOffs()
    }

    useEffect(() => {
        setMainSettings((prev) => ({
            ...prev,
            limitationForecast: preselectForecasts,
            powerOffForecast: preselectForecasts,
        }))
    }, [preselectForecasts])

    return (
        <div>
            {newSystems && latestLimitations && latestPowerOffs ? (
                <Table className="limitations__table" responsive>
                    <thead>
                        <tr>
                            <th>
                                <Form.Check
                                    type="checkbox"
                                    onChange={(e) => {
                                        const checked = e.target.checked
                                        return setAllSystemsSelect(checked)
                                    }}
                                    checked={allSystemsSelect || false}
                                />
                            </th>
                            <th>System</th>
                            <th style={{ minWidth: '40rem' }}>
                                <div className="d-flex align-items-center">
                                    <div className=" flex-shrink-0">
                                        Limitation
                                        <InputGroup style={{ width: '5.5rem' }}>
                                            <Form.Control
                                                aria-label="Limitation"
                                                onChange={(e) => {
                                                    const value = e.target.value
                                                    setMainSettings((prev) => ({ ...prev, percent: value }))
                                                }}
                                                value={mainSettings.percent || ''}
                                                style={{ appearance: 'textfield' }}
                                                type="number"
                                            />
                                            <InputGroup.Append>
                                                <InputGroup.Text>%</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                    </div>
                                    <div className="ml-2 flex-shrink-0">
                                        From
                                        <LimitationDate
                                            date={mainSettings.limitation_date_from}
                                            setDate={(val) =>
                                                setMainSettings((prev) => ({ ...prev, limitation_date_from: val }))
                                            }
                                        />
                                    </div>
                                    <div className="ml-2 flex-shrink-0">
                                        To{' '}
                                        <LimitationDate
                                            date={mainSettings.limitation_date_to}
                                            setDate={(val) =>
                                                setMainSettings((prev) => ({ ...prev, limitation_date_to: val }))
                                            }
                                            dateTo
                                        />
                                    </div>
                                    <div className="ml-2  flex-shrink-0">
                                        <Form.Check
                                            type="checkbox"
                                            onChange={(e) => {
                                                const checked = e.target.checked
                                                return setMainSettings((prev) => ({
                                                    ...prev,
                                                    limitationForecast: checked,
                                                }))
                                            }}
                                            checked={mainSettings.limitationForecast || false}
                                        />
                                    </div>
                                </div>
                            </th>
                            <th style={{ minWidth: '38rem' }}>
                                <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 align-self-start">
                                        Off / On{' '}
                                        <Form.Check
                                            type="switch"
                                            id="main-system-switch"
                                            checked={mainSettings.powerOff}
                                            onChange={(e) => {
                                                const checked = e.target.checked
                                                setMainSettings((prev) => ({ ...prev, powerOff: checked }))
                                            }}
                                        />
                                    </div>
                                    <div className="ml-2 flex-shrink-0">
                                        From
                                        <LimitationDate
                                            date={mainSettings.powerOff_date_from}
                                            setDate={(val) =>
                                                setMainSettings((prev) => ({ ...prev, powerOff_date_from: val }))
                                            }
                                        />
                                    </div>
                                    <div className="ml-2 flex-shrink-0">
                                        To{' '}
                                        <LimitationDate
                                            date={mainSettings.powerOff_date_to}
                                            setDate={(val) =>
                                                setMainSettings((prev) => ({ ...prev, powerOff_date_to: val }))
                                            }
                                            dateTo
                                        />
                                    </div>
                                    <div className="ml-2 flex-shrink-0">
                                        <Form.Check
                                            type="checkbox"
                                            onChange={(e) => {
                                                const checked = e.target.checked
                                                return setMainSettings((prev) => ({
                                                    ...prev,
                                                    powerOffForecast: checked,
                                                }))
                                            }}
                                            checked={mainSettings.powerOffForecast || false}
                                        />
                                    </div>
                                </div>
                            </th>

                            <th>Correction</th>
                            <th style={{ verticalAlign: 'bottom' }}>
                                <Button onClick={saveAllLimitations}>Save</Button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {newSystems
                            ?.filter((system) => system.features.limitation)
                            ?.sort((a, b) =>
                                a.description.localeCompare(
                                    b.description,
                                    navigator.languages[0] || navigator.language,
                                    {
                                        numeric: true,
                                        ignorePunctuation: true,
                                    }
                                )
                            )
                            .map((system) => {
                                // const powerOff = latestPowerOffs?.find(
                                //     (powerOff) => powerOff.system === system.system && isActive(powerOff.date_to)
                                // )
                                // const limitation = latestLimitations?.find(
                                //     (limitation) => limitation.system === system.system && isActive(limitation.date_to)
                                // )
                                return (
                                    <tr
                                        key={system.system}
                                        style={system.isSaving ? { opacity: 0.5, pointerEvents: 'none' } : {}}
                                    >
                                        <td>
                                            <Form.Check
                                                type="checkbox"
                                                onChange={(e) => {
                                                    const checked = e.target.checked

                                                    setNewSystems((prev) =>
                                                        prev.map((prevSystem) =>
                                                            prevSystem.system === system.system
                                                                ? {
                                                                      ...prevSystem,
                                                                      isSelected: checked,
                                                                      ...(checked && {
                                                                          ...mainSettings,
                                                                      }),
                                                                  }
                                                                : { ...prevSystem }
                                                        )
                                                    )
                                                }}
                                                checked={system.isSelected || false}
                                            />
                                        </td>
                                        <td>
                                            <Link to={`/system/${system.system}/detail`}>
                                                <strong>{system.description}</strong>
                                            </Link>
                                        </td>
                                        <td className={system.activeLimitation ? 'table-success' : ''}>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-shrink-0">
                                                    <InputGroup style={{ width: '5.5rem' }}>
                                                        <Form.Control
                                                            aria-label="Limitation"
                                                            value={system.percent || ''}
                                                            type="number"
                                                            style={{
                                                                appearance: 'textfield',
                                                                WebkitAppearance: 'none',
                                                            }}
                                                            onChange={(e) => {
                                                                const value = e.target.value
                                                                return setNewSystems((prev) =>
                                                                    prev.map((prevSystem) =>
                                                                        prevSystem.system === system.system
                                                                            ? { ...prevSystem, percent: value }
                                                                            : { ...prevSystem }
                                                                    )
                                                                )
                                                            }}
                                                        />
                                                        <InputGroup.Append>
                                                            <InputGroup.Text>%</InputGroup.Text>
                                                        </InputGroup.Append>
                                                    </InputGroup>
                                                </div>
                                                <div className="ml-2 flex-shrink-0">
                                                    <LimitationDate
                                                        date={system.limitation_date_from}
                                                        disabled={system.activeLimitation}
                                                        setDate={(newDate) =>
                                                            setNewSystems((prev) =>
                                                                prev.map((prevSystem) =>
                                                                    prevSystem.system === system.system
                                                                        ? {
                                                                              ...prevSystem,
                                                                              limitation_date_from: newDate,
                                                                          }
                                                                        : { ...prevSystem }
                                                                )
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="ml-2 flex-shrink-0">
                                                    <LimitationDate
                                                        date={system.limitation_date_to}
                                                        setDate={(newDate) =>
                                                            setNewSystems((prev) =>
                                                                prev.map((prevSystem) =>
                                                                    prevSystem.system === system.system
                                                                        ? { ...prevSystem, limitation_date_to: newDate }
                                                                        : { ...prevSystem }
                                                                )
                                                            )
                                                        }
                                                        dateTo
                                                    />
                                                </div>
                                                <div className="ml-2 flex-shrink-0">
                                                    <Form.Check
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            const checked = e.target.checked

                                                            setNewSystems((prev) =>
                                                                prev.map((prevSystem) =>
                                                                    prevSystem.system === system.system
                                                                        ? {
                                                                              ...prevSystem,
                                                                              limitationForecast: checked,
                                                                          }
                                                                        : { ...prevSystem }
                                                                )
                                                            )
                                                        }}
                                                        checked={system.limitationForecast || false}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td className={system.activePowerOff ? 'table-danger' : ''}>
                                            <div className="d-flex align-items-center">
                                                <div style={{ minWidth: '3.875rem' }}>
                                                    <Form.Check
                                                        type="switch"
                                                        onChange={(e) => {
                                                            const checked = e.target.checked
                                                            return setNewSystems((prev) =>
                                                                prev.map((prevSystem) =>
                                                                    prevSystem.system === system.system
                                                                        ? { ...prevSystem, powerOff: checked }
                                                                        : { ...prevSystem }
                                                                )
                                                            )
                                                        }}
                                                        id={`system-${system.system}-switch`}
                                                        checked={system.powerOff || false}
                                                    />
                                                </div>
                                                <div className="ml-2 flex-shrink-0">
                                                    <LimitationDate
                                                        date={system.powerOff_date_from}
                                                        disabled={system.activeLimitation}
                                                        setDate={(newDate) =>
                                                            setNewSystems((prev) =>
                                                                prev.map((prevSystem) =>
                                                                    prevSystem.system === system.system
                                                                        ? { ...prevSystem, powerOff_date_from: newDate }
                                                                        : { ...prevSystem }
                                                                )
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="ml-2 flex-shrink-0">
                                                    <LimitationDate
                                                        date={system.powerOff_date_to}
                                                        setDate={(newDate) =>
                                                            setNewSystems((prev) =>
                                                                prev.map((prevSystem) =>
                                                                    prevSystem.system === system.system
                                                                        ? { ...prevSystem, powerOff_date_to: newDate }
                                                                        : { ...prevSystem }
                                                                )
                                                            )
                                                        }
                                                        dateTo
                                                    />
                                                </div>
                                                <div className="ml-2 flex-shrink-0">
                                                    <Form.Check
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            const checked = e.target.checked

                                                            setNewSystems((prev) =>
                                                                prev.map((prevSystem) =>
                                                                    prevSystem.system === system.system
                                                                        ? {
                                                                              ...prevSystem,
                                                                              powerOffForecast: checked,
                                                                          }
                                                                        : { ...prevSystem }
                                                                )
                                                            )
                                                        }}
                                                        checked={system.powerOffForecast || false}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td>
                                            <Button
                                                onClick={async () => {
                                                    await saveSingleLimitation(system)
                                                    fetchLimitations()
                                                    fetchPowerOffs()
                                                }}
                                            >
                                                Save
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })}
                    </tbody>
                </Table>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    )
}
