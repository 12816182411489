import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import setForecastCorrection from '../../services/fotomon/setForecastCorrection'
import LimitationModal from './LimitationModalNew'

const initalValues = {
    dateTimeFrom: moment().add(1, 'minute'),
    dateTimeTo: moment().add(1, 'hour'),
    percent: 100,
}

export default function ForecastCorrectionModal({ show, onHide, editedLimitation, systemId, fetchData }) {
    const intl = useIntl()
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [dateTimeFrom, setDateTimeFrom] = useState(initalValues.dateTimeFrom)

    const [dateTimeTo, setDateTimeTo] = useState(initalValues.dateTimeTo)

    const [correction, setCorrection] = useState(initalValues.percent)
    useEffect(() => {
        if (show === false) {
            return
        }

        if (!editedLimitation) {
            setDateTimeFrom(moment().add(1, 'minute'))
            setDateTimeTo(moment().add(1, 'hour'))
            setCorrection(initalValues.percent)
            return
        }
        setDateTimeFrom(moment(editedLimitation.date_from))
        setDateTimeTo(moment(editedLimitation.date_to))
        setCorrection(editedLimitation.percent.toString())
    }, [editedLimitation, show])

    const onSubmit = async () => {
        setIsSaving(true)
        try {
            await setForecastCorrection({
                dateTimeFrom,
                dateTimeTo,
                systemId,
                correction: parseFloat(correction) / 100,
                ...(editedLimitation ? { forecastCorrectionId: editedLimitation.id } : {}),
            })
        } catch (e) {
            console.log(e)
            setError('Error setting forecast correction')
            setSuccess(true)
        }
        await fetchData()
        setIsSaving(false)
    }

    const onDateTimeFromChange = (date) => {
        setDateTimeFrom(date)
    }

    const onDateTimeToChange = (date) => {
        setDateTimeTo(date)
    }

    const onPercentChange = (e) => {
        const value = e.target.value
        value <= 150 && setCorrection(value)
    }

    const onHideWithMessagesReset = () => {
        setSuccess(false)
        setError(null)
        onHide()
    }
    return (
        <LimitationModal
            title={intl.formatMessage({
                id: editedLimitation ? 'forecast-correction.edit' : 'forecast-correction.setup',
                defaultMessage: editedLimitation ? 'Edit forecast correction' : 'Set forecast correction',
            })}
            show={show}
            onHide={onHideWithMessagesReset}
            onSubmit={onSubmit}
            onDateTimeFromChange={onDateTimeFromChange}
            onDateTimeToChange={onDateTimeToChange}
            dateTimeFrom={dateTimeFrom}
            dateTimeTo={dateTimeTo}
            dateTimeFromDisabled={editedLimitation && dateTimeFrom.isBefore(moment())}
            onPercentChange={onPercentChange}
            percent={correction}
            error={error}
            setError={setError}
            isSaving={isSaving}
            setSuccess={setSuccess}
            success={success}
        />
    )
}
