import moment from 'moment'
import 'moment-timezone'
import React, { useEffect, useRef, useState } from 'react'
import { Alert, Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import Datetime from 'react-datetime'
import { FormattedMessage, useIntl } from 'react-intl'
import { Calendar } from '../../shared/components/Icons'

function useInputProps(value) {
    const inputRef = useRef(null)
    const [cursorPosition, setCursorPosition] = useState(0)

    useEffect(() => {
        if (inputRef.current !== document.activeElement) return
        inputRef.current.setSelectionRange(cursorPosition, cursorPosition)
    }, [value, cursorPosition])

    return {
        inputRef,
        onInput: () => setCursorPosition(inputRef.current.selectionStart),
    }
}

const languagePathPart = window.location.pathname.split('/')[1]
const language = languagePathPart === 'en' ? 'cs' : languagePathPart
moment.locale(language)

export default function LimitationModal({
    title,
    show,
    onHide,
    onSubmit,
    onDateTimeFromChange,
    onDateTimeToChange,
    dateTimeFrom,
    dateTimeTo,
    dateTimeFromDisabled,
    onPercentChange,
    percent,
    error,
    setError,
    isSaving,
    success,
    setSuccess,
}) {
    const intl = useIntl()
    const isValidDate = (e) => {
        if (typeof e === 'string') {
            return
        }
        const now = moment() // Current time
        // Check if the date is today
        const isToday = e.isSame(moment(), 'day')
        // Check if the date is in the future
        const isFutureDate = e.isAfter(moment(), 'day')
        // Check if the time is after the current time (if the date is today)
        const isTimeAfterNow = e.isAfter(moment())

        return (isToday && (isTimeAfterNow || e.isSame(now))) || isFutureDate
    }

    const { inputRef: dateTimeFromInputRef, onInput: onDateTimeFromInput } = useInputProps(dateTimeFrom)

    const { inputRef: dateTimeToInputRef, onInput: onDateTimeToInput } = useInputProps(dateTimeTo)

    const limitationValidationAlerts = () => {
        const currentTimestamp = moment().unix()
        const fromTimestamp = dateTimeFrom.unix()
        const toTimestamp = dateTimeTo.unix()
        if (fromTimestamp <= currentTimestamp || toTimestamp <= currentTimestamp) {
            alert(
                intl.formatMessage({
                    id: 'limitations.pastDate',
                    defaultMessage:
                        'Start or End date and time of the limitation cannot be in past (next minute at least)',
                })
            )
            return
        }

        if (fromTimestamp >= toTimestamp) {
            alert(
                intl.formatMessage({
                    id: 'limitations.startEndDateWrong',
                    defaultMessage: 'Start date and time cannot be same or after End date and time of the limitation',
                })
            )
            return
        }
    }
    return (
        <Modal centered show={show} onHide={onHide} className="modal--add-limitation">
            <Modal.Header closeButton>
                <Modal.Title className="h5">{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Form
                    onSubmit={(e) => {
                        e.preventDefault()
                        limitationValidationAlerts()
                        onSubmit()
                    }}
                >
                    <Form.Group as={Row}>
                        <Form.Label xs="12" md="3" className="text-right" column>
                            <FormattedMessage id="limitations.start" defaultMessage="From" />
                        </Form.Label>
                        <Col xs="12" md="auto">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <Calendar style={{ color: 'black', height: '1rem' }} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Datetime
                                    isValidDate={isValidDate}
                                    onChange={onDateTimeFromChange}
                                    value={dateTimeFrom}
                                    locale={language}
                                    inputProps={{
                                        className: 'form-control text-right',
                                        disabled: dateTimeFromDisabled,
                                        ref: dateTimeFromInputRef,
                                        onInput: onDateTimeFromInput,
                                        style:
                                            !dateTimeFromDisabled && !isValidDate(dateTimeFrom) ? { color: 'red' } : {},
                                    }}
                                    dateFormat="D.M.YYYY"
                                    timeFormat="HH:mm"
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text>{moment().tz(moment.tz.guess()).format('z')}</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label xs="12" md="3" className="text-right" column>
                            <FormattedMessage id="limitations.end" defaultMessage="To" />
                        </Form.Label>
                        <Col xs="12" md="auto">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text>
                                        <Calendar style={{ color: 'black', height: '1rem' }} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Datetime
                                    isValidDate={isValidDate}
                                    onChange={onDateTimeToChange}
                                    value={dateTimeTo}
                                    locale={language}
                                    inputProps={{
                                        className: 'form-control text-right',
                                        ref: dateTimeToInputRef,
                                        onInput: onDateTimeToInput,
                                    }}
                                    dateFormat="D.M.YYYY"
                                    timeFormat="HH:mm"
                                />
                                <InputGroup.Append>
                                    <InputGroup.Text>{moment().tz(moment.tz.guess()).format('z')}</InputGroup.Text>
                                </InputGroup.Append>
                            </InputGroup>
                        </Col>
                    </Form.Group>
                    {percent !== undefined && (
                        <Form.Group as={Row} className="align-items-center">
                            <Form.Label xs="12" md="3" className="text-right" column>
                                <FormattedMessage id="limitations.limitto" defaultMessage="Limit to" />
                            </Form.Label>
                            <Col xs="12" md="auto">
                                <InputGroup>
                                    <Form.Control
                                        type="number"
                                        value={percent || ''}
                                        onChange={(e) => onPercentChange(e)}
                                        min={0}
                                        max={150}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            %{' '}
                                            <FormattedMessage
                                                id="limitations.nominalpower"
                                                defaultMessage="of nominmal power"
                                            />
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Col>
                        </Form.Group>
                    )}

                    <Alert variant="danger" dismissible show={!!error} onClose={() => setError(null)}>
                        {error}
                    </Alert>

                    <Alert variant="success" dismissible show={success} onClose={() => setSuccess(null)}>
                        Saved successfully
                    </Alert>
                    <div className="text-right">
                        <Button variant="outline-secondary" className="mr-2" onClick={onHide}>
                            <FormattedMessage id="close" defaultMessage="Close" />
                        </Button>
                        <Button type="submit" disabled={isSaving}>
                            {isSaving ? (
                                <FormattedMessage id="loading" />
                            ) : (
                                <FormattedMessage id="limitations.submit" defaultMessage="Set" />
                            )}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
