import { faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import usePowerOffs from '../../../shared/hooks/usePowerOff'
import PowerOffsModal from '../../limitations/PowerOffsModal'

export default function PowerOff({ systemId, powerOffs, fetchPowerOffs }) {
    const { deletePowerOff, powerOnBySystemId } = usePowerOffs()

    const [showModal, setShowModal] = useState(false)
    const currentTimestamp = moment().unix()
    const isActive = (dateTo) => moment(dateTo).unix() >= currentTimestamp

    const powerOff = powerOffs?.find((powerOff) => powerOff.system === systemId && isActive(powerOff.date_to))

    return (
        <>
            <Button
                variant={powerOff ? 'success' : 'danger'}
                onClick={() => (powerOff ? powerOnBySystemId(systemId) : setShowModal(true))}
                className="mr-3"
            >
                <FontAwesomeIcon icon={faPowerOff} className="mr-2" />
                <FormattedMessage
                    id={powerOff ? 'powerOn' : 'powerOff'}
                    defaultMessage={powerOff ? 'Power On' : 'Power Off'}
                />
            </Button>
            <PowerOffsModal
                fetchData={fetchPowerOffs}
                powerOff
                show={showModal}
                onHide={() => setShowModal(false)}
                systemIds={[systemId]}
            />
        </>
    )
}
