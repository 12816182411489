import getApiUrl from '../../shared/lib/getApiUrl'

const identity = JSON.parse(window.localStorage.getItem('identity'))
export default async function setLimitation({ dateTimeFrom, dateTimeTo, editedLimitationId, systemId, percent }) {
    const response = await fetch(getApiUrl('/api/v1/limitations/'), {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user: identity.user,
            date_from: dateTimeFrom,
            date_to: dateTimeTo,
            system: systemId,
            percent,
            ...(editedLimitationId ? { limitation: editedLimitationId } : {}),
        }),
    })

    if (!response.ok) {
        throw new Error('Failed to put limitation')
    }

    return response.json()
}
