import moment from 'moment'
import React, { useMemo } from 'react'
import { InputGroup } from 'react-bootstrap'
import Datetime from 'react-datetime'
import { Calendar } from '../../shared/components/Icons'

export default function LimitationDate({ editedLimitation, forecastCorrection, setDate, date, dateTo, disabled }) {
    // const nowDateTime = useMemo(
    //     () =>
    //         moment().format('D.M.YYYY') +
    //         moment()
    //             .add(
    //                 dateTo
    //                     ? forecastCorrection
    //                         ? moment().format('mm') > 5
    //                             ? 5
    //                             : 4
    //                         : 1
    //                     : forecastCorrection && (moment().format('mm') > 5 ? 4 : 3),
    //                 'hours'
    //             ) // IF FORECAST CORRECTION - ADD HOURS
    //             .add(1, 'minute')
    //             .format(' HH:mm'),
    //     []
    // )

    // const afterHourDateTime =
    //     show && // IF MODAL SHOW
    //     moment().format('D.M.YYYY') +
    //         moment()
    //             .add(forecastCorrection ? (moment().format('mm') > 5 ? 5 : 4) : 1, 'hours') // IF FORECAST CORRECTION - ADD HOURS
    //             .add(1, 'minute')
    //             .format(' HH:mm')

    const languagePathPart = window.location.pathname.split('/')[1]
    const language = languagePathPart === 'en' ? 'cs' : languagePathPart
    moment.locale(language)

    const dateValid = (e) => e.isAfter(moment())

    return (
        <InputGroup className="flex-nowrap" disabled={disabled}>
            <InputGroup.Prepend>
                <InputGroup.Text>
                    <Calendar style={{ color: 'black', height: '1rem' }} />
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Datetime
                isValidDate={(e) => dateValid(e)}
                onChange={setDate}
                value={date}
                locale={language}
                inputProps={{
                    className: 'form-control text-right',
                    disabled: editedLimitation ? moment().unix() > moment(editedLimitation.date_from).unix() : disabled,
                }}
                className="flex-shrink-0"
            />
            <InputGroup.Append>
                <InputGroup.Text>{moment().tz(moment.tz.guess()).format('z')}</InputGroup.Text>
            </InputGroup.Append>
        </InputGroup>
    )
}
