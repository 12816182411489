import { faCog, faPowerOff } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useMemo, useState } from 'react'
import { Button, ButtonGroup, Form, Tab, Table, Tabs } from 'react-bootstrap'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import useFetch from '../../shared/hooks/useFetch'
import useLimitations from '../../shared/hooks/useLimitations'
import usePowerOffs from '../../shared/hooks/usePowerOff'
import useSystems from '../../shared/hooks/useSystems'
import LimitationModal from './LimitationModal'
import LimitationsTable from './LimitationsTable'

export default function Limitations() {
    const { data: systems } = useFetch({ url: '/api/v1/systems' })
    const { limitations, fetchLimitations } = useLimitations()
    const { powerOffs, fetchPowerOffs, deletePowerOff } = usePowerOffs()
    const [selectedSystems, setSelectedSystems] = useState([])
    const [showLimitationModal, setShowLimitationModal] = useState(false)
    const [showPowerOffModal, setShowPowerOffModal] = useState(false)

    const currentTimestamp = moment().unix()
    const isActive = (dateTo) => moment(dateTo).unix() >= currentTimestamp
    // useEffect(() => {
    //     limitations &&
    //         setSelectedSystems([
    //             ...limitations
    //                 .filter(({ date_to }) => isActive(date_to))
    //                 .map((limitation) => ({ systemId: limitation.system, limtation: limitation.limitation })),
    //         ])
    // }, [limitations])

    const formatDate = (date) => `${moment(date).format('D.M.YYYY')} ${moment(date).format('HH:mm')}`

    const latestLimitations = useMemo(() => {
        return limitations
            ?.filter((limitation) => moment(limitation.date_from).unix() > moment().subtract(7, 'days').unix())
            .sort((a, b) => moment(b.date_from).unix() - moment(a.date_from).unix())
    }, [limitations])

    const latestPowerOffs = useMemo(() => {
        return powerOffs
            ?.filter((powerOff) => moment(powerOff.date_from).unix() > moment().subtract(7, 'days').unix())
            .sort((a, b) => moment(b.date_from).unix() - moment(a.date_from).unix())
    }, [powerOffs])

    const intl = useIntl()
    const [preselectForecasts, setPreselectForecasts] = useState(false)
    return (
        <div>
            {/* <div className="d-flex mb-4">
                <Button className="mr-3" onClick={() => setShowLimitationModal(true)}>
                    <FontAwesomeIcon icon={faCog} className="mr-2" />
                    <FormattedMessage id="limitations.setupLimit" defaultMessage="Limit" />
                </Button>
                <Button variant="danger" onClick={() => setShowPowerOffModal(true)}>
                    <FontAwesomeIcon icon={faPowerOff} className="mr-2" />
                    <FormattedMessage id="powerOff" defaultMessage="Power Off" />
                </Button>
            </div> */}
            {/* <Tabs defaultActiveKey="ukrenergo">
                <Tab eventKey="ukrenergo" title="Ukrenergo">
                  
                </Tab>
                <Tab eventKey="gpee" title="Gpee">
                    <LimitationsTable preselectForecasts />
                </Tab>
            </Tabs> */}
            <ButtonGroup className="mb-2">
                <Button
                    onClick={() => setPreselectForecasts(false)}
                    variant={preselectForecasts ? 'outline-primary' : 'primary'}
                >
                    Ukrenergo
                </Button>
                <Button
                    onClick={() => setPreselectForecasts(true)}
                    variant={preselectForecasts ? 'primary' : 'outline-primary'}
                >
                    Gpee
                </Button>
            </ButtonGroup>

            <LimitationsTable preselectForecasts={preselectForecasts} />

            {/* <Table>
                <thead>
                    <tr>
                        <td style={{ background: '#333', color: 'white' }}>
                            <Form.Check
                                type="checkbox"
                                className="ml-2"
                                id="select_all"
                                onChange={(e) =>
                                    setSelectedSystems(
                                        e.target.checked
                                            ? [
                                                  ...systems.map((system) => ({
                                                      systemId: system.system,
                                                      limitation: system.limitation,
                                                  })),
                                              ]
                                            : []
                                    )
                                }
                            />
                        </td>
                        <td style={{ background: '#333', color: 'white' }}>
                            <strong>
                                {' '}
                                <FormattedMessage id="description" defaultMessage="Description" />
                            </strong>
                        </td>
                        <td style={{ background: '#333', color: 'white' }}>
                            <strong>
                                <FormattedMessage id="userdescription" defaultMessage="User description" />
                            </strong>
                        </td>
                        <td style={{ background: '#333', color: 'white' }}>
                            {' '}
                            <FormattedMessage id="limitations.limited" defaultMessage="Limited" />
                        </td>
                        <td style={{ background: '#333', color: 'white' }}>
                            <FormattedMessage id="powerOff.off" defaultMessage="Powered Off" />
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {systems
                        ?.filter((system) => system.features.limitation)
                        ?.sort((a, b) =>
                            a.description.localeCompare(b.description, navigator.languages[0] || navigator.language, {
                                numeric: true,
                                ignorePunctuation: true,
                            })
                        )
                        .map((system) => {
                            const powerOff = latestPowerOffs?.find(
                                (powerOff) => powerOff.system === system.system && isActive(powerOff.date_to)
                            )

                            const poweredOffCellStyle = powerOff ? { background: '#ffbebe' } : {}

                            const limitation = latestLimitations?.find(
                                (limitation) => limitation.system === system.system && isActive(limitation.date_to)
                            )

                            return (
                                <tr key={system.system}>
                                    <td style={poweredOffCellStyle}>
                                        <Form.Check
                                            type="checkbox"
                                            className="ml-2"
                                            id={system.id}
                                            checked={selectedSystems.some(
                                                (selectedSystem) => selectedSystem.systemId === system.system
                                            )}
                                            onChange={() =>
                                                setSelectedSystems((prev) =>
                                                    prev.some(
                                                        (selectedSystem) => selectedSystem.systemId === system.system
                                                    )
                                                        ? prev.filter(
                                                              (selectedSystem) =>
                                                                  selectedSystem.systemId !== system.system
                                                          )
                                                        : [
                                                              ...prev,
                                                              {
                                                                  systemId: system.system,
                                                                  limitation: system.limitation,
                                                              },
                                                          ]
                                                )
                                            }
                                        />
                                    </td>
                                    <td style={poweredOffCellStyle}>
                                        <Link to={`/system/${system.system}/detail`}>
                                            <strong>{system.description}</strong>
                                        </Link>
                                    </td>
                                    <td style={poweredOffCellStyle}>{system.user_description}</td>
                                    <td style={poweredOffCellStyle}>
                                        {limitation && (
                                            <span style={{ whiteSpace: 'nowrap' }}>
                                                {formatDate(limitation?.date_from)} - {formatDate(limitation?.date_to)}
                                            </span>
                                        )}
                                    </td>
                                    <td style={poweredOffCellStyle}>
                                        {powerOff && (
                                            <span style={{ whiteSpace: 'nowrap' }}>
                                                {formatDate(powerOff?.date_from)} - {formatDate(powerOff?.date_to)}
                                            </span>
                                        )}
                                        {powerOff && (
                                            <Button
                                                size="sm"
                                                variant="success"
                                                className="px-2 py-1 ml-2"
                                                onClick={() => {
                                                    deletePowerOff(powerOff)
                                                }}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPowerOff}
                                                    title={intl.formatMessage({
                                                        id: `limitations.setup`,
                                                        defaultMessage: 'Update power Limitation',
                                                    })}
                                                />
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            )
                        })}
                </tbody>
            </Table>
            <LimitationsModal
                show={showLimitationModal}
                onHide={() => setShowLimitationModal(false)}
                systemIds={selectedSystems.map((system) => system.systemId)}
                fetchData={fetchLimitations}
            />
            <PowerOffsModal
                show={showPowerOffModal}
                onHide={() => setShowPowerOffModal(false)}
                systemIds={selectedSystems}
                fetchData={fetchPowerOffs}
            />
            {/* <LimitationModal
                show={showPowerOffModal}
                onHide={() => setShowPowerOffModal(false)}
                powerOff
                selectedSystems={selectedSystems}
                fetchData={fetchPowerOffs}
            /> */}
        </div>
    )
}
