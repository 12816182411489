import getApiUrl from '../../shared/lib/getApiUrl'

const identity = JSON.parse(window.localStorage.getItem('identity'))
export default async function setPowerOff({ dateTimeFrom, dateTimeTo, editedPowerOffId, systemId }) {
    const response = await fetch(getApiUrl('/api/v1/poweroff/'), {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user: identity.user,
            date_from: dateTimeFrom,
            date_to: dateTimeTo,
            system: systemId,
            ...(editedPowerOffId ? { poweroff: editedPowerOffId } : {}),
        }),
    })

    if (!response.ok) {
        throw new Error('Failed to put power off')
    }

    return response.json()
}
