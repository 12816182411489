import moment from 'moment'
import getApiUrl from './getApiUrl'

export default async function setLimitation({
    selectedSystem,
    forecastCorrection,
    editedLimitation,
    powerOff,
    limitation,
    intl,
}) {
    let startTimestamp = moment(limitation.date_from).unix()
    let endTimestamp = moment(limitation.date_to).unix()
    let currentTimestamp = moment().unix()
    if (
        !forecastCorrection &&
        !editedLimitation &&
        (startTimestamp <= currentTimestamp || endTimestamp <= currentTimestamp)
    ) {
        alert(
            intl.formatMessage({
                id: 'limitations.pastDate',
                defaultMessage: 'Start or End date and time of the limitation cannot be in past (next minute at least)',
            })
        )
        return
    }
    if (!forecastCorrection && startTimestamp >= endTimestamp) {
        alert(
            intl.formatMessage({
                id: 'limitations.startEndDateWrong',
                defaultMessage: 'Start date and time cannot be same or after End date and time of the limitation',
            })
        )
        return
    }
    const res = await fetch(
        getApiUrl(
            forecastCorrection
                ? '/api/v1/forecast-correction/'
                : powerOff
                ? `/api/v1/poweroff/`
                : `/api/v1/limitations/${editedLimitation?.limitation ?? ''}`
        ),
        {
            method: 'PUT',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...limitation,
                system: selectedSystem,
                ...(editedLimitation && { ...editedLimitation }),
            }),
        }
    )
    const data = await res.json()
    return data
}
