import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import setLimitation from '../../services/fotomon/setLimitation'
import LimitationModal from './LimitationModalNew'
const initalValues = {
    dateTimeFrom: moment().add(1, 'minute'),
    dateTimeTo: moment().add(1, 'hour'),
    percent: 100,
}

export default function LimitationsModal({ show, onHide, editedLimitation, systemIds, fetchData }) {
    const intl = useIntl()
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [dateTimeFrom, setDateTimeFrom] = useState(initalValues.dateTimeFrom)

    const [dateTimeTo, setDateTimeTo] = useState(initalValues.dateTimeTo)

    const [percent, setPercent] = useState(initalValues.percent)

    useEffect(() => {
        if (show === false) {
            return
        }

        if (!editedLimitation) {
            setDateTimeFrom(moment().add(1, 'minute'))
            setDateTimeTo(moment().add(1, 'hour'))
            setPercent(initalValues.percent)
            return
        }
        setDateTimeFrom(moment(editedLimitation.date_from))
        setDateTimeTo(moment(editedLimitation.date_to))
        setPercent(editedLimitation.percent.toString())
    }, [editedLimitation, show])

    const onSubmit = async () => {
        setIsSaving(true)
        for (const systemId of systemIds) {
            try {
                await setLimitation({
                    dateTimeFrom,
                    dateTimeTo,
                    systemId,
                    percent,
                    ...(editedLimitation ? { editedLimitationId: editedLimitation.limitation } : {}),
                })
                setSuccess(true)
            } catch (e) {
                console.log(e)
                setError('Error setting limitation')
            }
        }

        await fetchData()
        setIsSaving(false)
    }

    const onDateTimeFromChange = (date) => {
        setDateTimeFrom(date)
    }

    const onDateTimeToChange = (date) => {
        setDateTimeTo(date)
    }

    const onPercentChange = (e) => {
        const value = e.target.value
        value <= 150 && setPercent(value)
    }

    const onHideWithMessagesReset = () => {
        setSuccess(false)
        setError(null)
        onHide()
    }
    return (
        <LimitationModal
            title={intl.formatMessage({
                id: 'limitations.setup',
                defaultMessage: 'Set power limitation',
            })}
            show={show}
            onHide={onHideWithMessagesReset}
            onSubmit={onSubmit}
            onDateTimeFromChange={onDateTimeFromChange}
            onDateTimeToChange={onDateTimeToChange}
            dateTimeFrom={dateTimeFrom}
            dateTimeTo={dateTimeTo}
            dateTimeFromDisabled={editedLimitation && dateTimeFrom.isBefore(moment())}
            onPercentChange={onPercentChange}
            percent={percent}
            error={error}
            setError={setError}
            isSaving={isSaving}
            setSuccess={setSuccess}
            success={success}
        />
    )
}
