import getApiUrl from '../../shared/lib/getApiUrl'

const identity = JSON.parse(window.localStorage.getItem('identity'))
export default async function setForecastCorrection({
    dateTimeFrom,
    dateTimeTo,
    forecastCorrectionId,
    systemId,
    correction,
}) {
    const response = await fetch(getApiUrl('/api/v1/forecast-correction/'), {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            user: identity.user,
            date_from: dateTimeFrom,
            date_to: dateTimeTo,
            system: systemId,
            correction,
            ...(forecastCorrectionId ? { id: forecastCorrectionId } : {}),
        }),
    })

    if (!response.ok) {
        throw new Error('Failed to put forecast correction')
    }

    return response.json()
}
