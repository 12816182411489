import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import setPowerOff from '../../services/fotomon/setPowerOff'
import LimitationModal from './LimitationModalNew'

const initalValues = {
    dateTimeFrom: moment().add(1, 'minute'),
    dateTimeTo: moment().add(1, 'hour'),
}

export default function PowerOffsModal({ show, onHide, editedPowerOff, systemIds, fetchData }) {
    const intl = useIntl()
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)
    const [isSaving, setIsSaving] = useState(false)
    const [dateTimeFrom, setDateTimeFrom] = useState(initalValues.dateTimeFrom)

    const [dateTimeTo, setDateTimeTo] = useState(initalValues.dateTimeTo)

    useEffect(() => {
        if (show === false) {
            return
        }

        if (!editedPowerOff) {
            setDateTimeFrom(moment().add(1, 'minute'))
            setDateTimeTo(moment().add(1, 'hour'))
            return
        }
        setDateTimeFrom(moment(editedPowerOff.date_from))
        setDateTimeTo(moment(editedPowerOff.date_to))
    }, [editedPowerOff, show])

    const onSubmit = async () => {
        setIsSaving(true)
        for (const systemId of systemIds) {
            try {
                await setPowerOff({
                    dateTimeFrom,
                    dateTimeTo,
                    systemId,
                    ...(editedPowerOff ? { editedPowerOff: editedPowerOff.poweroff } : {}),
                })
            } catch (e) {
                console.log(e)
                setError('Error setting power off')
                setSuccess(true)
            }
        }

        await fetchData()
        setIsSaving(false)
    }

    const onDateTimeFromChange = (date) => {
        setDateTimeFrom(date)
    }

    const onDateTimeToChange = (date) => {
        setDateTimeTo(date)
    }

    const onHideWithMessagesReset = () => {
        setSuccess(false)
        setError(null)
        onHide()
    }

    return (
        <LimitationModal
            title={intl.formatMessage({
                id: editedPowerOff ? 'powerOff.edit' : 'powerOff.setup',
                defaultMessage: editedPowerOff ? 'Edit power turn off' : 'Set up power turn off',
            })}
            show={show}
            onHide={onHideWithMessagesReset}
            onSubmit={onSubmit}
            onDateTimeFromChange={onDateTimeFromChange}
            onDateTimeToChange={onDateTimeToChange}
            dateTimeFrom={dateTimeFrom}
            dateTimeTo={dateTimeTo}
            dateTimeFromDisabled={editedPowerOff && dateTimeFrom.isBefore(moment())}
            error={error}
            setError={setError}
            isSaving={isSaving}
            setSuccess={setSuccess}
            success={success}
        />
    )
}
